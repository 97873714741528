import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from 'antd';
import cls from './ConfirmationModal.module.css';
import { Button, ButtonVariants } from 'shared/components';
var ConfirmationModal = function (_a) {
    var children = _a.children, open = _a.open, text = _a.text, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    return (_jsx(Popover, { open: open, placement: "leftBottom", arrow: false, getPopupContainer: function () {
            return document.getElementById('app-layout') || document.body;
        }, content: _jsxs("div", { className: cls.content, children: [_jsx("div", { children: text }), _jsxs("div", { className: cls.buttons, children: [_jsx(Button, { classNamesProps: cls.cancelButton, type: "button", text: "Cancel", variant: ButtonVariants.ACTION, onClick: onCancel }), _jsx(Button, { classNamesProps: cls.confirmButton, type: "button", text: "Confirm", variant: ButtonVariants.ACTION, onClick: onConfirm })] })] }), children: children }));
};
export default ConfirmationModal;
