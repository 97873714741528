import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import cls from './Text.module.css';
export var TextVariants;
(function (TextVariants) {
    TextVariants["TITLE"] = "title";
    TextVariants["TITLE_REVERSED"] = "titleReversed";
    TextVariants["TITLE_LARGE"] = "titleLarge";
    TextVariants["TITLE_LARGE_REVERSED"] = "titleLargeReversed";
    TextVariants["TITLE_MEDIUM"] = "titleMedium";
    TextVariants["TITLE_MEDIUM_REVERSED"] = "titleMediumReversed";
    TextVariants["TITLE_EXTRA_SMALL"] = "titleExtraSmall";
    TextVariants["TITLE_EXTRA_SMALL_REVERSED"] = "titleExtraSmallReversed";
    TextVariants["SUBTITLE_MEDIUM"] = "subtitleMedium";
    TextVariants["SUBTITLE_MEDIUM_REVERSED"] = "subtitleMediumReversed";
    TextVariants["SUBTITLE_SMALL"] = "subtitleSmall";
    TextVariants["PARAGRAPH_TITLE_MEDIUM"] = "paragraphTitleMedium";
    TextVariants["PARAGRAPH_TITLE"] = "paragraphTitle";
    TextVariants["PARAGRAPH_DARK_MEDIUM"] = "paragraphDarkMedium";
    TextVariants["PARAGRAPH_DARK"] = "paragraphDark";
    TextVariants["PARAGRAPH_BLACK"] = "paragraphBlack";
    TextVariants["PARAGRAPH_DISABLED"] = "paragraphDisabled";
    TextVariants["PARAGRAPH_REVERSED"] = "paragraphReversed";
    TextVariants["PARAGRAPH_REVERSED_SMALL"] = "paragraphReversedSmall";
    TextVariants["ACTION"] = "action";
    TextVariants["ACTION_RED"] = "actionRed";
    TextVariants["BADGE"] = "badge";
    TextVariants["BADGE_BLUE"] = "badgeBlue";
    TextVariants["DEFAULT"] = "default";
    TextVariants["DEFAULT_SMALL"] = "defaultSmall";
    TextVariants["SUBHEADER_LIGHT"] = "subheaderLight";
    TextVariants["SUBHEADER_LIGHT_SMALL"] = "subheaderLightSmall";
    TextVariants["CARD_TITLE"] = "cardTitle";
})(TextVariants || (TextVariants = {}));
export var Text = function (props) {
    var children = props.children, variant = props.variant, tag = props.tag, width = props.width, classNamesProps = props.classNamesProps;
    var classes = classNames(cls.text, cls[variant], classNamesProps);
    if (tag === 'h1') {
        return (_jsx("h1", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'h2') {
        return (_jsx("h2", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'h3') {
        return (_jsx("h3", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'h4') {
        return (_jsx("h4", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'h5') {
        return (_jsx("h5", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'h6') {
        return (_jsx("h6", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    if (tag === 'span') {
        return (_jsx("span", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
    }
    return (_jsx("p", { style: { width: "".concat(width + 'px' || 'auto') }, className: classes, children: children }));
};
