var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
var useQsParams = function () {
    var _a = useSearchParams(), searchParams = _a[0], _setSearchParams = _a[1];
    var currentUri = searchParams.toString();
    var existingParams = qs.parse(currentUri);
    var cleanup = function () { return function (v) {
        return _.isNil(v) || (_.isArray(v) && _.every(v, _.isNil)) || v === '';
    }; };
    var setSearchParams = function (field, value) {
        var _a;
        var params = _.omitBy(__assign(__assign({}, existingParams), (_a = {}, _a[field] = value, _a)), cleanup());
        _setSearchParams(params);
    };
    var setSearchParamsAsObject = function (payload, overwrite) {
        if (overwrite === void 0) { overwrite = false; }
        var values = !overwrite
            ? __assign(__assign({}, existingParams), payload) : __assign({}, payload);
        var params = _.omitBy(values, cleanup());
        _setSearchParams(params);
    };
    var resetSearchParams = function (e) {
        if (e === void 0) { e = undefined; }
        if (e)
            e.preventDefault();
        _setSearchParams({});
    };
    return {
        searchParams: searchParams,
        setSearchParams: setSearchParams,
        resetSearchParams: resetSearchParams,
        setSearchParamsAsObject: setSearchParamsAsObject,
        // aliases
        qs: searchParams,
        setQs: setSearchParams,
        resetQs: resetSearchParams,
    };
};
export default useQsParams;
