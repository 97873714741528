import { RoutePaths } from '../AppRouter';
export var PUBLIC_ROUTES = [
    RoutePaths.LOGIN,
    RoutePaths.SIGNUP,
    RoutePaths.PASSWORDRESET,
    RoutePaths.INVITE,
    RoutePaths.ACTIONS,
    RoutePaths.NEW_PASSWORD,
    RoutePaths.VERIFY_EMAIL,
];
/**
 * Generates a URL with an optional redirect parameter for authentication flow.
 * If the user tries to access a protected route, they will be redirected to the login page
 * with the original destination encoded in the redirect parameter. For public routes,
 * the original path is preserved.
 */
export var generateDeepLinkUrl = function (path) {
    var _a = window.location, search = _a.search, pathname = _a.pathname;
    var deepLinkUrl = encodeURIComponent("".concat(pathname).concat(search));
    var isPublicRoute = PUBLIC_ROUTES.includes(path);
    // Skip redirect parameter for login page since it's the authentication entry point
    var isLoginPage = path === RoutePaths.LOGIN;
    if (isLoginPage)
        return path;
    var isEmptyDeepLink = deepLinkUrl === '%2F';
    var suffix = !isEmptyDeepLink ? "?redirect=".concat(deepLinkUrl) : '';
    var route = isPublicRoute ? path : RoutePaths.LOGIN;
    return "".concat(route).concat(suffix);
};
/**
 * Constructs a URL path by combining the provided path with any redirect parameter from the URL.
 * If a redirect parameter exists in the URL, it decodes it and appends it to the given path.
 * For dashboard paths, the initial path is removed to prevent path duplication.
 */
export var assembleDeepLinkUrl = function (path) {
    var searchParams = new URLSearchParams(window.location.search);
    var redirectPath = searchParams.get('redirect');
    if (redirectPath) {
        var decodedPath = decodeURIComponent(redirectPath);
        path = path === RoutePaths.DASHBOARD ? '' : path;
        return path + decodedPath;
    }
    return path;
};
