import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getRouteConfig } from '../config/getRouteConfig';
import { Context } from 'app/providers/ContextProvider';
import Loader from 'shared/components/Loader/Loader';
export var AppRouter = function () {
    var routeConfig = getRouteConfig();
    var onResize = useContext(Context).onResize;
    window.addEventListener('resize', function () {
        var screenWidth = window.innerWidth;
        onResize(screenWidth);
    });
    useEffect(function () {
        onResize(window.innerWidth);
    }, []);
    return (_jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsx(Routes, { children: routeConfig.map(function (_a) {
                var path = _a.path, element = _a.element;
                return (_jsx(Route, { path: path, element: element }, path));
            }) }) }));
};
