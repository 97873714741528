import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Loader from 'shared/components/Loader/Loader';
import cls from './FirebaseActions.module.css';
var FirebaseActionModes;
(function (FirebaseActionModes) {
    FirebaseActionModes["RESET_PASSWORD"] = "resetPassword";
    FirebaseActionModes["VERIFY_EMAIL"] = "verifyEmail";
})(FirebaseActionModes || (FirebaseActionModes = {}));
var FirebaseActions = function () {
    var search = useLocation().search;
    var navigate = useNavigate();
    useEffect(function () {
        var params = new URLSearchParams(search);
        var mode = params.get('mode');
        var oobCode = params.get('oobCode');
        var userId = params.get('user');
        if (!mode || !oobCode) {
            navigate('/login');
        }
        else {
            switch (mode) {
                case FirebaseActionModes.RESET_PASSWORD:
                    navigate('/new-password?oobCode=' + oobCode);
                    break;
                case FirebaseActionModes.VERIFY_EMAIL:
                    navigate("/verify-email?oobCode=".concat(oobCode, "&user=").concat(userId));
                    break;
                default:
                    navigate('/login');
                    break;
            }
        }
    }, []);
    return (_jsx("div", { className: cls.container, children: _jsx(Loader, {}) }));
};
export default FirebaseActions;
