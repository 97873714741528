var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useState } from 'react';
import cls from './Table.module.css';
import { Pagination } from 'antd';
import Loader from '../Loader/Loader';
var Table = function (_a) {
    var headers = _a.headers, data = _a.data, loading = _a.loading, className = _a.className, pagination = _a.pagination, mergeRowData = _a.mergeRowData, _b = _a.noDataLabel, noDataLabel = _b === void 0 ? 'No Data' : _b;
    var defaultCurrent = 1;
    var defaultPageSize = 10;
    var _c = useState(data), tableData = _c[0], setTableData = _c[1];
    var onPaginationChange = function (pageNumber, limit) {
        var startIndex = (pageNumber - 1) * limit;
        var paginatedData = __spreadArray([], data, true).splice(startIndex, limit);
        setTableData(paginatedData);
    };
    useLayoutEffect(function () {
        if (Array.isArray(mergeRowData) && data.length > 0) {
            var formattedMergedData_1 = {};
            mergeRowData.forEach(function (label, index) {
                if (label in formattedMergedData_1) {
                    if (data[index] !== undefined) {
                        formattedMergedData_1[label].push(data[index].splice(1, data[index].length - 1));
                    }
                }
                else {
                    formattedMergedData_1[label] = [data[index]];
                }
            });
            setTableData(formattedMergedData_1);
        }
        else {
            setTableData(data);
        }
    }, [data]);
    useEffect(function () {
        if (pagination) {
            onPaginationChange(defaultCurrent, defaultPageSize);
        }
    }, [pagination]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: cls.table_responsive, children: _jsxs("table", { className: "".concat(cls.table, " ").concat(mergeRowData ? cls.merge_row_table : '', " ").concat(className ? className : ''), children: [_jsx("thead", { className: cls.tableHead, children: _jsx("tr", { children: headers.map(function (header, index) { return (_jsx("th", { children: header }, index)); }) }) }), _jsx("tbody", { className: cls.tableBody, children: mergeRowData
                                ? Array.isArray(tableData)
                                    ? null
                                    : Object.entries(tableData).map(function (_a) {
                                        var label = _a[0], values = _a[1];
                                        var rowSpan = values.length;
                                        return (Array.isArray(values) &&
                                            values.map(function (row, rIndex) {
                                                if (row === undefined)
                                                    return null;
                                                return (_jsx("tr", { children: Array.isArray(row) &&
                                                        row.map(function (cell, index) {
                                                            if (row.length -
                                                                1 ===
                                                                index) {
                                                                return null;
                                                            }
                                                            cell =
                                                                rIndex ===
                                                                    0 &&
                                                                    index ===
                                                                        0 ? (_jsxs("div", { className: cls.action_button_wrapper, children: [cell, row.at(-1)] })) : (cell);
                                                            return (_jsx("td", { rowSpan: rIndex ===
                                                                    0 &&
                                                                    index ===
                                                                        0
                                                                    ? rowSpan
                                                                    : undefined, children: cell }, "".concat(rIndex, "-").concat(index, "-").concat(label)));
                                                        }) }, rIndex + label));
                                            }));
                                    })
                                : tableData.map(function (row, index) {
                                    return (_jsx("tr", { children: row.map(function (cell, index) {
                                            return (_jsx("td", { children: cell }, index));
                                        }) }, index));
                                }) })] }) }), loading ? (_jsx("div", { className: cls.noData, children: _jsx(Loader, {}) })) : !loading && !(data === null || data === void 0 ? void 0 : data.length) ? (_jsx("div", { className: cls.noData, children: noDataLabel })) : null, pagination && (_jsx(Pagination, { className: cls.antPagination, simple: true, disabled: !data.length, showSizeChanger: true, defaultCurrent: defaultCurrent, defaultPageSize: defaultPageSize, total: data.length, showTotal: function (total, range) {
                    return "".concat(range[0], "-").concat(range[1], " of ").concat(total, " items");
                }, onChange: onPaginationChange }))] }));
};
export default Table;
