import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Tooltip.module.css';
import { Text, TextVariants } from 'shared/components';
import classNames from 'classnames';
export var TooltipVariants;
(function (TooltipVariants) {
    TooltipVariants["FLAG"] = "flag";
    TooltipVariants["INFO"] = "info";
})(TooltipVariants || (TooltipVariants = {}));
export var Tooltip = function (props) {
    var text = props.text, variant = props.variant;
    return (_jsx("div", { className: classNames(cls.tooltip, cls[variant]), children: _jsxs("div", { className: cls.text, children: [_jsx(Text, { tag: "p", variant: variant === TooltipVariants.INFO ? TextVariants.PARAGRAPH_REVERSED : TextVariants.SUBTITLE_MEDIUM, classNamesProps: cls.textContent, children: text }), _jsx("div", { className: cls.arrow })] }) }));
};
