var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { UserContext } from './user-context';
import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from '../AuthProvider/auth-context';
import { useBannerContext } from '../BannerProvider/banner-context';
var GET_CURRENT_USER_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query GetCurrentUser {\n        currentUser {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            invoices\n            role\n            organization {\n                id\n                name\n                tier {\n                    id\n                    name\n                }\n                licenses {\n                    id\n                    licenseName\n                    account {\n                        id\n                        isDefault\n                    }\n                }\n                users {\n                    id\n                    firstName\n                    lastName\n                }\n            }\n        }\n    }\n"], ["\n    query GetCurrentUser {\n        currentUser {\n            id\n            firebaseUid\n            firstName\n            lastName\n            email\n            invoices\n            role\n            organization {\n                id\n                name\n                tier {\n                    id\n                    name\n                }\n                licenses {\n                    id\n                    licenseName\n                    account {\n                        id\n                        isDefault\n                    }\n                }\n                users {\n                    id\n                    firstName\n                    lastName\n                }\n            }\n        }\n    }\n"])));
export var UserProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), user = _b[0], setUser = _b[1];
    var isAuthorized = useAuthContext().isAuthorized;
    var banner = useBannerContext();
    var logout = useAuthContext().logout;
    var _c = useQuery(GET_CURRENT_USER_QUERY, {
        skip: !isAuthorized, // Skip the query if it's not authorized
    }), data = _c.data, error = _c.error, refetch = _c.refetch;
    useEffect(function () {
        if (error) {
            banner.show('Error fetching user data');
            logout();
        }
        else {
            setUser((data === null || data === void 0 ? void 0 : data.currentUser) || null);
        }
    }, [data, error]);
    var value = {
        user: user,
        setUser: setUser,
        refetch: refetch,
    };
    return (_jsx(UserContext.Provider, { value: value, children: children }));
};
var templateObject_1;
