import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import cls from './NavigationLink.module.css';
import ArrowGreenIcon from 'shared/assets/icons/arrow-green-right.svg';
export var NavigationLinkVariants;
(function (NavigationLinkVariants) {
    NavigationLinkVariants["DEFAULT"] = "default";
    NavigationLinkVariants["ACTION"] = "action";
    NavigationLinkVariants["OUTLINED"] = "outlined";
    NavigationLinkVariants["ACTION_BLUE"] = "actionBlue";
    NavigationLinkVariants["OUTLINED_BLUE"] = "outlinedBlue";
    NavigationLinkVariants["ACTION_TEXT"] = "actionText";
    NavigationLinkVariants["FOOTER"] = "footer";
})(NavigationLinkVariants || (NavigationLinkVariants = {}));
export var NavigationLink = memo(function (props) {
    var _a;
    var text = props.text, to = props.to, variant = props.variant, classNamesProps = props.classNamesProps, _b = props.isEnterprise, isEnterprise = _b === void 0 ? false : _b, onClick = props.onClick;
    var classes = classNames(cls.navigationLink, cls[variant], classNamesProps, (_a = {}, _a[cls.dark] = isEnterprise, _a));
    return (_jsxs(Link, { to: to, className: classes, onClick: onClick, children: [text, _jsx("div", { className: cls.icon, children: variant === NavigationLinkVariants.ACTION_TEXT && _jsx(ArrowGreenIcon, {}) })] }));
});
NavigationLink.displayName = 'NavigationLink';
