var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { OrganizationContext } from './organization-context';
import { gql, useQuery } from '@apollo/client';
import { useBannerContext } from '../BannerProvider/banner-context';
export var OrganizationTier;
(function (OrganizationTier) {
    OrganizationTier["FREE"] = "Free";
    OrganizationTier["PRO"] = "Pro";
})(OrganizationTier || (OrganizationTier = {}));
var ORGANIZATION_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query Organization($organizationId: ID!) {\n        organization(organizationId: $organizationId) {\n            id\n            name\n            users {\n                id\n                firstName\n                lastName\n            }\n        }\n    }\n"], ["\n    query Organization($organizationId: ID!) {\n        organization(organizationId: $organizationId) {\n            id\n            name\n            users {\n                id\n                firstName\n                lastName\n            }\n        }\n    }\n"])));
var ORGANIZATION_TIER_QUERY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query OrganizationTier {\n        organizationTier {\n            id\n            name\n            features {\n                accounts {\n                    isMaxAccountsReached\n                    accountsCount\n                    maxAccounts\n                }\n            }\n            organization {\n                id\n                name\n            }\n        }\n    }\n"], ["\n    query OrganizationTier {\n        organizationTier {\n            id\n            name\n            features {\n                accounts {\n                    isMaxAccountsReached\n                    accountsCount\n                    maxAccounts\n                }\n            }\n            organization {\n                id\n                name\n            }\n        }\n    }\n"])));
export var OrganizationProvider = function (_a) {
    var _b;
    var children = _a.children, organizationId = _a.organizationId;
    var _c = useState(), organization = _c[0], setOrganization = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState(), error = _e[0], setError = _e[1];
    var show = useBannerContext().show;
    var organizationQuery = useQuery(ORGANIZATION_QUERY, {
        variables: {
            organizationId: organizationId,
        },
        skip: !organizationId,
    });
    var organizationTier = useQuery(ORGANIZATION_TIER_QUERY);
    useEffect(function () {
        var _a;
        if (!organizationQuery.loading) {
            setLoading(false);
            setOrganization((_a = organizationQuery.data) === null || _a === void 0 ? void 0 : _a.organization);
            setError(organizationQuery.error);
        }
    }, [organizationQuery]);
    useEffect(function () {
        if (error) {
            show(error.message);
        }
    }, [error]);
    return (_jsx(OrganizationContext.Provider, { value: {
            tier: {
                loading: organizationTier.loading,
                error: organizationTier.error,
                organizationTier: (_b = organizationTier.data) === null || _b === void 0 ? void 0 : _b.organizationTier,
            },
            organization: organization,
            error: error,
            loading: loading,
        }, children: children }));
};
var templateObject_1, templateObject_2;
