var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import DeleteIcon from 'shared/assets/icons/delete-icon.svg';
import DeleteIconDisabled from 'shared/assets/icons/delete-icon-disabled.svg';
import ConfirmationModal from 'widgets/ConfirmationModal/ConfirmationModal';
import { BannerTypes, useBannerContext, } from 'app/providers/BannerProvider/banner-context';
import cls from '../UsersScreen.module.css';
import { isAdminOrSuperAdmin } from '../helpers';
var DELETE_USER_MUTATION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation DeleteUser($id: ID!) {\n        deleteUser(id: $id)\n    }\n"], ["\n    mutation DeleteUser($id: ID!) {\n        deleteUser(id: $id)\n    }\n"])));
export var DeleteUserButton = function (_a) {
    var user = _a.user;
    var _b = useState(''), userToDelete = _b[0], setUserToDelete = _b[1];
    var show = useBannerContext().show;
    var onDeleteClick = function () { return setUserToDelete(user === null || user === void 0 ? void 0 : user.id); };
    var onDeleteCancel = function () { return setUserToDelete(''); };
    var deleteUserMutation = useMutation(DELETE_USER_MUTATION, {
        refetchQueries: ['UsersByOrganization'],
    })[0];
    var onDeleteConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteUserMutation({
                            variables: { id: user === null || user === void 0 ? void 0 : user.id },
                        })];
                case 1:
                    result = _a.sent();
                    if (result.errors) {
                        show(result.errors[0].message);
                    }
                    else {
                        show('User deleted successfully', BannerTypes.SUCCESS);
                        setUserToDelete('');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    show(e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return isAdminOrSuperAdmin(user) ? (_jsxs("div", { className: "".concat(cls.actions, " ").concat(cls.actions_disable), children: [_jsx("span", { className: "for_des", children: "Delete" }), _jsx(DeleteIconDisabled, {})] })) : (_jsx(ConfirmationModal, { open: (user === null || user === void 0 ? void 0 : user.id) === userToDelete, text: _jsxs("span", { className: cls.deleteConfirmationText, children: ["Are you sure you want to delete user", ' ', _jsxs("span", { children: [user === null || user === void 0 ? void 0 : user.firstName, " ", user === null || user === void 0 ? void 0 : user.lastName] }), "?"] }), onConfirm: onDeleteConfirm, onCancel: onDeleteCancel, children: _jsxs("div", { className: cls.actions, onClick: onDeleteClick, children: [_jsx("span", { className: "for_des", children: "Delete" }), _jsx(DeleteIcon, {})] }) }));
};
var templateObject_1;
