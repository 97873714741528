import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useContext, useEffect, useState } from 'react';
import DropdownIconClose from 'shared/assets/icons/arrow-dropdown-down.svg';
import DropdownIconOpen from 'shared/assets/icons/arrow-dropdown-up.svg';
import cls from './NavigationDropdown.module.css';
import classNames from 'classnames';
import { Context } from 'app/providers/ContextProvider';
import { WebNavigationDropdown } from 'shared/components/NavigationDropdown/components/WebNavigationDropdown';
export var NavigationDropdown = memo(function (props) {
    var _a;
    var children = props.children, title = props.title, isEnterprise = props.isEnterprise, open = props.open, changeDropdownState = props.changeDropdownState, classNamesProps = props.classNamesProps, mobile = props.mobile;
    var screenWidth = useContext(Context).screenWidth;
    var _b = useState(false), isWeb = _b[0], setIsWeb = _b[1];
    useEffect(function () {
        if (screenWidth > 1438)
            setIsWeb(true);
        else
            setIsWeb(false);
    }, [screenWidth]);
    if (isWeb) {
        return (_jsx(WebNavigationDropdown, { title: title, isEnterprise: isEnterprise, open: open, changeDropdownState: changeDropdownState, classNamesProps: classNamesProps, children: children }));
    }
    return (_jsxs("div", { className: classNames(cls.dropdownContainer, (_a = {}, _a[cls.dark] = isEnterprise, _a[cls.open] = open, _a)), children: [_jsxs("button", { onClick: function () { return changeDropdownState(true); }, className: cls.dropdownButton, children: [title, _jsx("div", { className: cls.icon, children: open ?
                            _jsx(DropdownIconOpen, {}) :
                            _jsx(DropdownIconClose, { fill: isEnterprise ? '#fff' : '#161414' }) })] }), !mobile && open &&
                _jsxs(React.Fragment, { children: [_jsx("div", { className: classNames(cls.dropdown, classNamesProps), children: children }), _jsx("div", { onClick: function () { return changeDropdownState(false); }, className: cls.overlay })] }), mobile &&
                _jsxs(React.Fragment, { children: [_jsx("div", { className: classNames(cls.dropdown, classNamesProps), children: children }), _jsx("div", { onClick: function () { return changeDropdownState(false); }, className: cls.overlay })] })] }));
});
NavigationDropdown.displayName = 'NavigationDropdown';
