var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UserRoles } from 'app/interfaces/User';
import { useUserContext } from 'app/providers/UserProvider/user-context';
/**
 * A custom React hook for handling role-based and permission-based access control.
 *
 * @param roles - An array of either UserRoles (strings) or AccessControlRole objects
 *                that define the required roles and permissions for access.
 *
 * @returns boolean - Returns true if a user exists, false otherwise
 *
 * @example
 * // Basic role-based access control
 * useAccessControl([UserRoles.ADMIN, UserRoles.SUPER_ADMIN])
 *
 * // Role and permission-based access control
 * useAccessControl([
 *   { role: UserRoles.USER, permissions: ['invoices', 'another_field_in_user_object'] },
 *   UserRoles.ADMIN
 * ])
 *
 * @description
 * This hook performs two types of access control:
 * 1. Role-based: Checks if the user has one of the required roles
 * 2. Permission-based: Checks if the user has the specified permissions
 *
 * If the user doesn't have the required role or permissions, they are
 * redirected to the dashboard page ('/dashboard').
 *
 * The hook automatically runs whenever the user data, roles array, or
 * navigation function changes.
 *
 * @typedef {Object} AccessControlRole
 * @property {string[]} permissions - Array of permission keys to check against user object
 * @property {UserRoles} role - The role associated with these permissions
 */
var useAccessControl = function (roles) {
    var user = useUserContext().user;
    var navigate = useNavigate();
    var userRole = (user === null || user === void 0 ? void 0 : user.role) || undefined;
    var allowedRoles = __spreadArray(__spreadArray([], roles, true), [UserRoles.SUPER_ADMIN], false);
    // Check if the user has the required permissions
    var checkPermissions = function (role) {
        if (!role || !user)
            return false;
        return role.every(function (permission) {
            var field = user[permission];
            return Boolean(field);
        });
    };
    useEffect(function () {
        if (!userRole)
            return;
        var matchedRole = allowedRoles.find(function (role) {
            var isString = typeof role === 'string';
            return isString ? userRole === role : userRole === role.role;
        });
        // If it's just a role-based check, we're done
        if (typeof matchedRole === 'string')
            return;
        // Check permissions if required
        if (!matchedRole || !checkPermissions(matchedRole === null || matchedRole === void 0 ? void 0 : matchedRole.permissions)) {
            navigate('/dashboard');
        }
    }, [user, allowedRoles, navigate]);
    return !!user;
};
export default useAccessControl;
