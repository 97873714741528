import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './InfoBadge.module.css';
import InfoIcon from 'shared/assets/icons/info.svg';
import { Tooltip, TooltipVariants } from 'shared/components';
import { useState } from 'react';
export var InfoBadge = function (_a) {
    var text = _a.text;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (_jsxs("div", { className: cls.container, children: [_jsx("button", { className: cls.button, onMouseOver: function () { return setOpen(true); }, onMouseLeave: function () { return setOpen(false); }, children: _jsx(InfoIcon, {}) }), open &&
                _jsx(Tooltip, { text: text, variant: TooltipVariants.INFO })] }));
};
