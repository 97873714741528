var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AuthContext } from './auth-context';
import { useBannerContext } from '../BannerProvider/banner-context';
import { onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signOut, } from 'firebase/auth';
import { auth } from 'firebase/firebase';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../AppRouter';
import { gql, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import { throttle } from 'lodash';
import dayjs from 'dayjs';
import useQsParams from 'shared/hooks/useQsParams';
import { assembleDeepLinkUrl, generateDeepLinkUrl } from './helpers';
var PUBLIC_ROUTES = [
    RoutePaths.LOGIN,
    RoutePaths.SIGNUP,
    RoutePaths.PASSWORDRESET,
    RoutePaths.INVITE,
    RoutePaths.ACTIONS,
    RoutePaths.NEW_PASSWORD,
    RoutePaths.VERIFY_EMAIL,
];
var ENV = process.env.NODE_ENV;
var REFRESH_INTERVAL = 59 * 60 * 1000; // 59 minutes
var FIVE_MINUTES_IDLE = 5 * 60 * 1000;
var SESSION_TIME_HOURS = 24;
var REVOKE_TOKEN_MUTATION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation RevokeToken($firebaseUid: String!) {\n        revokeUserToken(firebaseUid: $firebaseUid)\n    }\n"], ["\n    mutation RevokeToken($firebaseUid: String!) {\n        revokeUserToken(firebaseUid: $firebaseUid)\n    }\n"])));
var CHECK_PASSWORD_RESET_MUTATION = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation CheckPasswordReset {\n        checkPasswordReset {\n            passwordExpired\n            firstTime\n            hasCustomerId\n        }\n    }\n"], ["\n    mutation CheckPasswordReset {\n        checkPasswordReset {\n            passwordExpired\n            firstTime\n            hasCustomerId\n        }\n    }\n"])));
var UPDATE_EXPIRED_PASSWORD_MUTATION = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation UpdateExpiredPassword($password: String!) {\n        updateExpiredPassword(password: $password)\n    }\n"], ["\n    mutation UpdateExpiredPassword($password: String!) {\n        updateExpiredPassword(password: $password)\n    }\n"])));
var UPDATE_FORGOT_PASSWORD_MUTATION = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation UpdateForgotPassword($password: String!, $oobCode: String!) {\n        updateForgotPassword(password: $password, oobCode: $oobCode)\n    }\n"], ["\n    mutation UpdateForgotPassword($password: String!, $oobCode: String!) {\n        updateForgotPassword(password: $password, oobCode: $oobCode)\n    }\n"])));
export var AuthProvider = function (_a) {
    var children = _a.children, updateApolloClient = _a.updateApolloClient;
    var navigate = useNavigate();
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), isAuthorized = _c[0], setIsAuthorized = _c[1];
    var _d = useState(false), isPending = _d[0], setIsPending = _d[1];
    var show = useBannerContext().show;
    var qs = useQsParams().qs;
    var revokeUserToken = useMutation(REVOKE_TOKEN_MUTATION)[0];
    var checkPasswordReset = useMutation(CHECK_PASSWORD_RESET_MUTATION)[0];
    var updateExpiredPassword = useMutation(UPDATE_EXPIRED_PASSWORD_MUTATION)[0];
    var updateForgotPassword = useMutation(UPDATE_FORGOT_PASSWORD_MUTATION)[0];
    // Handle auth state changed
    // TODO: Refactor this effect. It's too complex and hard to understand/predict already
    useEffect(function () {
        var unsubscribe = onAuthStateChanged(auth, function () { return __awaiter(void 0, void 0, void 0, function () {
            var path, accessToken, inviteId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        path = window.location.pathname;
                        if (!(auth.currentUser && auth.currentUser.emailVerified)) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.currentUser.getIdToken(true)];
                    case 1:
                        accessToken = _a.sent();
                        updateApolloClient(accessToken);
                        setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, checkPasswordReset().then(function (_a) {
                                            var _b, _c, _d, _e, _f;
                                            var data = _a.data;
                                            var expiredPass = (_c = (_b = data === null || data === void 0 ? void 0 : data.checkPasswordReset) === null || _b === void 0 ? void 0 : _b.passwordExpired) !== null && _c !== void 0 ? _c : false;
                                            var firstTime = (_e = (_d = data === null || data === void 0 ? void 0 : data.checkPasswordReset) === null || _d === void 0 ? void 0 : _d.firstTime) !== null && _e !== void 0 ? _e : false;
                                            var isNeedProPlan = !((_f = data === null || data === void 0 ? void 0 : data.checkPasswordReset) === null || _f === void 0 ? void 0 : _f.hasCustomerId);
                                            // Logout if user goes to / and has expired password.
                                            // This prevents the user from being redirected to the dashboard
                                            // when they have expired password. Also prevents error because user data
                                            // might not be available anymore.
                                            if (expiredPass && path === '/') {
                                                signOut(auth);
                                                return;
                                            }
                                            // If user has expired password, redirect to /new-password
                                            if (expiredPass) {
                                                var action = "action=password-expired".concat(firstTime ? '&ft=1' : '');
                                                navigate("".concat(RoutePaths.NEW_PASSWORD, "?").concat(action));
                                                setIsPending(false);
                                                return null;
                                            }
                                            if (isNeedProPlan) {
                                                navigate("".concat(RoutePaths.PRO_PLAN));
                                                return null;
                                            }
                                            if (path === RoutePaths.LOGIN) {
                                                navigate(assembleDeepLinkUrl(RoutePaths.DASHBOARD));
                                            }
                                            setIsAuthorized(true);
                                            return;
                                        })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, 500);
                        return [3 /*break*/, 3];
                    case 2:
                        if (auth.currentUser && !auth.currentUser.emailVerified) {
                            if (!PUBLIC_ROUTES.includes(path) &&
                                path !== RoutePaths.VERIFY_EMAIL) {
                                navigate(RoutePaths.VERIFY_EMAIL);
                            }
                        }
                        else if (path === RoutePaths.INVITE) {
                            inviteId = qs.get('inviteId');
                            navigate("".concat(RoutePaths.INVITE, "?inviteId=").concat(inviteId));
                        }
                        else {
                            updateApolloClient('');
                            setIsAuthorized(false);
                            navigate(generateDeepLinkUrl(path));
                        }
                        _a.label = 3;
                    case 3:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); });
        // Clean up the subscription when the component unmounts
        return function () { return unsubscribe(); };
    }, []);
    // Refresh user token every 55 minutes
    useEffect(function () {
        var interval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!auth.currentUser) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.currentUser.getIdToken(true)];
                    case 1:
                        accessToken = _a.sent();
                        updateApolloClient(accessToken);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }, REFRESH_INTERVAL);
        return function () { return clearInterval(interval); };
    }, []);
    // Expire session after 24 hours of inactivity.
    // Idle time is reset with each mouse move, key press, etc.
    useEffect(function () {
        var timeoutId;
        var user = auth.currentUser;
        var handleExpireSession = function () {
            if (user === null || user === void 0 ? void 0 : user.uid) {
                var key = "firstLogin:".concat(user.uid);
                var storage = localStorage.getItem(key);
                var loginDate = dayjs(storage).toISOString();
                var sessionExpired = dayjs().diff(loginDate, 'hour', true) >=
                    SESSION_TIME_HOURS;
                if (!sessionExpired)
                    return resetTimer();
                show('Your session has expired');
                var variables = { firebaseUid: user.uid };
                revokeUserToken({ variables: variables }).then(function () {
                    logout();
                });
            }
        };
        // Create throttled version of resetTimer
        var resetTimer = throttle(function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleExpireSession, FIVE_MINUTES_IDLE);
        }, 5000); // Execute at most once every 5 seconds
        // Only set up listeners if we have a logged-in user
        if (user === null || user === void 0 ? void 0 : user.uid) {
            var events_1 = [
                'mousedown',
                'mousemove',
                'keypress',
                'scroll',
                'touchstart',
            ];
            // Attach listeners
            events_1.forEach(function (e) { return window.addEventListener(e, resetTimer); });
            // Start the initial timer
            resetTimer();
            // Cleanup
            return function () {
                clearTimeout(timeoutId);
                resetTimer.cancel(); // Cancel any pending throttled calls
                events_1.forEach(function (e) {
                    return window.removeEventListener(e, resetTimer);
                });
            };
        }
    }, [auth.currentUser]);
    // Revoke refresh token after 6 months
    useEffect(function () {
        var user = auth.currentUser;
        if (user) {
            var key = "firstLogin:".concat(user.uid);
            var firstLogin = localStorage.getItem(key);
            if (!firstLogin) {
                localStorage.setItem(key, new Date().toISOString());
            }
            else if (dayjs(firstLogin).diff(dayjs(), 'month', true) >= 6) {
                revokeUserToken({ variables: { firebaseUid: user.uid } }).then(function () {
                    logout();
                });
            }
        }
    }, [auth.currentUser]);
    var login = function (email, password) { return __awaiter(void 0, void 0, void 0, function () {
        var res, accessToken, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setIsPending(true);
                    return [4 /*yield*/, signInWithEmailAndPassword(auth, email, password).finally(function () { return setTimeout(function () { return setIsPending(false); }, 3000); })];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, res.user.getIdToken(true)];
                case 2:
                    accessToken = _a.sent();
                    accessToken && updateApolloClient(accessToken);
                    return [2 /*return*/, null];
                case 3:
                    e_1 = _a.sent();
                    return [2 /*return*/, e_1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var proxyLogin = function (token) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, signInWithCustomToken(auth, token)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, null];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, e_2.message];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var logout = function () {
        var domain = ENV === 'development' ? 'localhost' : '.tomato.ai';
        Cookies.remove('__iPl', { path: '/', domain: domain });
        var user = auth.currentUser;
        if (user) {
            var key = "firstLogin:".concat(user.uid);
            var hasLoginFlag = localStorage.getItem(key);
            if (hasLoginFlag)
                localStorage.removeItem(key);
        }
        return signOut(auth);
    };
    var updateUserPassword = function (password) { return __awaiter(void 0, void 0, void 0, function () {
        var oobCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    oobCode = qs.get('oobCode');
                    if (!oobCode) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateForgotPassword({ variables: { password: password, oobCode: oobCode } })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateExpiredPassword({ variables: { password: password } })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/, true];
            }
        });
    }); };
    var value = {
        isAuthorized: isAuthorized,
        isPending: isPending,
        login: login,
        logout: logout,
        proxyLogin: proxyLogin,
        updateUserPassword: updateUserPassword,
        setIsAuthorized: setIsAuthorized,
    };
    if (loading)
        return null;
    return (_jsx(AuthContext.Provider, { value: value, children: children }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
