var _a;
var UserRoles;
(function (UserRoles) {
    UserRoles["SUPER_ADMIN"] = "superAdmin";
    UserRoles["ADMIN"] = "admin";
    UserRoles["USER"] = "user";
})(UserRoles || (UserRoles = {}));
var UserRolesLabels = (_a = {},
    _a[UserRoles.SUPER_ADMIN] = 'Super Admin',
    _a[UserRoles.ADMIN] = 'Admin',
    _a[UserRoles.USER] = 'Viewer',
    _a);
export { UserRoles, UserRolesLabels };
