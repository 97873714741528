import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BannerContext, BannerTypes } from './banner-context';
import { notification } from 'antd';
export var BannerProvider = function (_a) {
    var children = _a.children;
    var _b = notification.useNotification(), api = _b[0], contextHolder = _b[1];
    var show = function (message, type) {
        if (type === void 0) { type = BannerTypes.ERROR; }
        switch (type) {
            case BannerTypes.ERROR:
                api.error({
                    message: message,
                    placement: 'bottomRight',
                    duration: 5,
                });
                break;
            case BannerTypes.SUCCESS:
                api.success({
                    message: message,
                    placement: 'bottomRight',
                    duration: 5,
                });
                break;
        }
    };
    var value = { show: show };
    return (_jsxs(BannerContext.Provider, { value: value, children: [_jsx("div", { "data-testid": "banner-container", children: children }), contextHolder] }));
};
