import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthContext } from 'app/providers/AuthProvider/auth-context';
import { Footer, Header } from 'widgets';
import { SideBar } from 'widgets/SideBar/SideBar';
import cls from './Layout.module.css';
import DowntimeScreen from 'sections/DowntimeScreen/DowntimeScreen';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRouteConfig } from 'app/providers/AppRouter/config/getRouteConfig';
var Layout = function (_a) {
    var children = _a.children;
    var isAuthorized = useAuthContext().isAuthorized;
    var _b = useState(false), isMenuVisible = _b[0], setIsMenuVisible = _b[1];
    var _c = useState(true), hideLayout = _c[0], setHideLayout = _c[1];
    var appRoutes = getRouteConfig();
    var location = useLocation();
    // TODO: Dynamically change these variables
    var isDowntime = false;
    var backOnlineDate = new Date();
    if (isDowntime) {
        return (_jsx("div", { className: "app light", children: _jsx(DowntimeScreen, { backOnlineDate: backOnlineDate }) }));
    }
    useEffect(function () {
        if (isMenuVisible) {
            document.body.classList.add('sidebar_open');
        }
        else {
            document.body.classList.remove('sidebar_open');
        }
    }, [isMenuVisible]);
    useEffect(function () {
        var _a;
        var route = appRoutes.find(function (r) { return r.path === location.pathname; });
        setHideLayout((_a = route === null || route === void 0 ? void 0 : route.hideLayout) !== null && _a !== void 0 ? _a : false);
    }, [location]);
    var withLayout = (_jsxs(_Fragment, { children: [_jsx(SideBar, { menuShow: isMenuVisible, setMenuShow: setIsMenuVisible }), _jsx(Header, { setMenuShow: setIsMenuVisible }), _jsx("div", { className: cls.container, children: _jsx("div", { className: cls.content, children: children }) }), _jsx(Footer, {})] }));
    return (_jsx("div", { className: "app light", id: "app-layout", children: isAuthorized && !hideLayout ? withLayout : children }));
};
export default Layout;
