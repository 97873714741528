import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { HeaderContext } from './header-context';
export var HeaderProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), actions = _b[0], setActions = _b[1];
    var _c = useState(''), pageTitle = _c[0], setPageTitle = _c[1];
    var value = {
        actions: actions,
        pageTitle: pageTitle,
        setActions: setActions,
        setPageTitle: setPageTitle,
    };
    return (_jsx(HeaderContext.Provider, { value: value, children: children }));
};
