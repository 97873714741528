import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import cls from './Users.module.css';
import { Helmet } from 'react-helmet';
import UsersScreen from 'sections/UsersScreen/UsersScreen';
import { useHeaderContext } from 'app/providers/HeaderProvider/header-context';
import { UserRoles } from 'app/interfaces/User';
import UsersHeaderActions from './UsersHeaderActions/UsersHeaderActions';
import useAccessControl from 'app/hooks/useAccessControl';
var ALLOWED_ROLES = [UserRoles.ADMIN];
var Users = function () {
    var _a = useHeaderContext(), setActions = _a.setActions, setPageTitle = _a.setPageTitle;
    var accessControl = useAccessControl(ALLOWED_ROLES);
    useEffect(function () {
        setPageTitle('');
        setActions(_jsx(UsersHeaderActions, {}));
    }, []);
    return !accessControl ? null : (_jsxs("main", { className: cls.main, children: [_jsx(Helmet, { children: _jsx("title", { children: "Tomato.ai Web Console | Managers" }) }), _jsx(UsersScreen, {})] }));
};
export default Users;
