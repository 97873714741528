import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
import { hiddenLinks } from 'shared/lib';
var defaultValue = {
    isMobile: false,
    mobileBreakpoint: 1023,
    screenWidth: window.innerWidth,
    onResize: function (_) { },
    onPlay: function (_) { },
    playedAudio: '',
    //temporary hidden links logic
    hiddenLinks: {
        blog: false,
        pricing: false,
        terms: false,
        security: false,
        social: false
    }
    //
};
export var Context = createContext(defaultValue);
export var ContextProvider = function (_a) {
    var children = _a.children;
    var mobileBreakpoint = 1023;
    var _b = useState(window.innerWidth), screenWidth = _b[0], setScreenWidth = _b[1];
    var _c = useState(false), isMobile = _c[0], setIsMobile = _c[1];
    var _d = useState(''), playedAudio = _d[0], setPlayedAudio = _d[1];
    var resizeHandler = function (width) {
        if (width < mobileBreakpoint) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
        setScreenWidth(width);
    };
    var playHandler = function (audio) {
        setPlayedAudio(audio);
    };
    var contextValue = {
        playedAudio: playedAudio,
        mobileBreakpoint: mobileBreakpoint,
        isMobile: isMobile,
        screenWidth: screenWidth,
        onResize: resizeHandler,
        onPlay: playHandler,
        //temporary hidden links logic
        hiddenLinks: hiddenLinks,
        //
    };
    return (_jsx(Context.Provider, { value: contextValue, children: children }));
};
