import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from '../NavigationDropdown.module.css';
import classNames from 'classnames';
import DropdownIconOpen from 'shared/assets/icons/arrow-dropdown-up.svg';
import DropdownIconClose from 'shared/assets/icons/arrow-dropdown-down.svg';
import React from 'react';
export var WebNavigationDropdown = function (props) {
    var _a;
    var children = props.children, title = props.title, isEnterprise = props.isEnterprise, open = props.open, changeDropdownState = props.changeDropdownState, classNamesProps = props.classNamesProps;
    return (_jsxs("div", { onMouseEnter: function () { return changeDropdownState(true); }, onMouseLeave: function () { return changeDropdownState(false); }, className: classNames(cls.dropdownContainer, (_a = {}, _a[cls.dark] = isEnterprise, _a[cls.open] = open, _a)), children: [_jsxs("button", { onClick: function () { return changeDropdownState(true); }, className: cls.dropdownButton, children: [title, _jsx("div", { className: cls.icon, children: open ?
                            _jsx(DropdownIconOpen, {}) :
                            _jsx(DropdownIconClose, { fill: isEnterprise ? '#fff' : '#161414' }) })] }), open &&
                _jsx(React.Fragment, { children: _jsx("div", { className: classNames(cls.dropdown, classNamesProps), children: children }) })] }));
};
