import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import cls from './Footer.module.css';
import FooterLogo from 'shared/assets/logos/footer-logo.svg';
import { RoutePaths } from 'app/providers/AppRouter';
import { Link } from 'react-router-dom';
export var Footer = memo(function (_a) {
    var size = _a.size, style = _a.style;
    return (_jsx("footer", { className: "".concat(cls.footer, " ").concat(size ? cls.full_footer : ''), style: style, children: _jsxs("div", { className: cls.content, children: [_jsxs("div", { className: cls.info, children: [_jsx("div", { className: cls.logoColumn, children: _jsx(Link, { className: cls.logo, to: RoutePaths.DASHBOARD, children: _jsx(FooterLogo, {}) }) }), _jsx("p", { className: cls.copyrights, children: "\u00A9 Copyrights 2024. Tomato.ai" })] }), _jsx("div", { className: cls.menu, children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Link, { to: 'https://tomato.ai/privacy/', title: "Privacy", target: "_blank", children: "Privacy" }) }), _jsx("li", { children: _jsx(Link, { to: 'https://tomato.ai/ethics/', title: "Ethics", target: "_blank", children: "Ethics" }) }), _jsx("li", { children: _jsx(Link, { to: 'https://tomato.ai/terms/', title: "Terms", target: "_blank", children: "Terms" }) }), _jsx("li", { children: _jsx(Link, { to: 'https://tomato.ai/request-support/', title: "Request Support", target: "_blank", children: "Request Support" }) })] }) }), _jsx("p", { className: "".concat(cls.copyrights, " ").concat(cls.copyrights_mobile), children: "\u00A9 Copyrights 2024. Tomato.ai" })] }) }));
});
Footer.displayName = 'Footer';
